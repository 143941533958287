/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticates";



Vue.use(Router);



const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/views/app/Index"),
   // component: () => import("@/views/app/sessions/Sessions"),
      beforeEnter: authenticate,
      //redirect: "/app/pages/Blank",
      redirect: "/dashboard",

      children: [
        // {
        //   path: "layout/:layout",
        //   component: () => import("@/views/app/Index")
        // },
        {
          path: "dashboard",
          component: () => import("@/views/app/projet_climatologie/Dashbord")
          
        },
       
        {
          path: "/app",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "institution/ddaep",
              component: () => import("@/views/app/projet_climatologie/institution/Page_Ddaep")
            },
            {
              path: "institution/atda",
              component: () => import("@/views/app/projet_climatologie/institution/Page_Atda")
            },
            {
              path: "institution/pole-commune",
              component: () => import("@/views/app/projet_climatologie/institution/Page_Association_Pole"),
              props: true
            },

            {
              path: "table/statistics-departemental",
              component: () => import("@/views/app/projet_climatologie/Page_statisque_tableau")
            },
            {
              path: "station-pluviometre",
              component: () => import("@/views/app/projet_climatologie/Page_sation_pluviometre")
            },
            {
              path: "collecte-donnees",
              component: () => import("@/views/app/projet_climatologie/Page_collecte_donnee_pluviometre")

            },

            {
              path: "account/account-admin-ddaep",
              component: () => import("@/views/app/projet_climatologie/auth/Page_authentification_DDAEP")
            },
            {
              path: "account/account-admin-atda",
              component: () => import("@/views/app/projet_climatologie/auth/Page_authentification_ATDA")
            },
            {
              path: "account/account-agent-ddaep",
              component: () => import("@/views/app/projet_climatologie/auth/Page_authentification_DDAEP_AGENT")
            },
            {
              path: "account/account-agent-ddaep-zone",
              component: () => import("@/views/app/projet_climatologie/auth/Page_authentification_DDAEP_AGENT_ZONE"),
              props: true
            }
            ,
            {
              path: "account/account-administrateur",
              component: () => import("@/views/app/projet_climatologie/auth/Page_authentification_ministere"),
              props: true
            },
            {
              path: "cartographie/map-station-pluviometre",
              component: () => import("@/views/app/projet_climatologie/googlemaps/Page_google_mape"),
              props: true
            },
            //=======departement====
            {
              path: "parametre/departement",
              component: () => import("@/views/app/projet_climatologie/parametre/page_departement"),
             
            },
            
            //=======commune=====
            {
              path: "parametre/commune",
              component: () => import("@/views/app/projet_climatologie/parametre/page_commune"),
             
            },
            //=======arrondissement=====
            {
              path: "parametre/arrondissement",
              component: () => import("@/views/app/projet_climatologie/parametre/page_arrondissement"),
              props: true
            },
            //=======village=====
            {
              path: "parametre/village",
              component: () => import("@/views/app/projet_climatologie/parametre/page_village"),
              props: true
            },

             //=======village=====
             {
              path: "charts/niveau-communale",
              component: () => import("@/views/app/projet_climatologie/statistique/Page_statisque_graphe_niveau_commune"),
              props: true
            },

            {
              path: "charts/niveau-departement",
              component: () => import("@/views/app/projet_climatologie/statistique/Page_statisque_graphe_niveau_departement"),
              props: true
            },
            {
              path: "charts/niveau-pole",
              component: () => import("@/views/app/projet_climatologie/statistique/Page_statisque_graphe_niveau_pole"),
              props: true
            },
            {
              path: "charts/niveau-periode",
              component: () => import("@/views/app/projet_climatologie/statistique/Page_statisque_graphe_niveau_periode"),
              props: true
            },
            


          ]
        },

        {
          path: "/app/pages",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "blank",
              component: () => import("@/views/app/pages/Blank")
            }
          ]
        }
      ]
    },
    {
      path: "/app/sessions",
      component: () => import("@/views/app/sessions/Sessions"),

      children: [
        {
          path: "sign-in",
          component: () => import("@/views/app/sessions/SignIn")
        },
        {
          path: "error",
          component: () => import("@/views/app/sessions/Error")
        },
        {
          path: "forgot",
          component: () => import("@/views/app/sessions/Forgot")
        },
        {
          path: "lockscreen",
          component: () => import("@/views/app/sessions/Lockscreen")
        },
        {
          path: "sign-in-two",
          name: "login",
          component: () => import("@/views/app/sessions/SignInTwo")
        },
        {
          path: "sign-in-three",
          component: () => import("@/views/app/sessions/SignInThree")
        },
        {
          path: "sign-in-four",
          component: () => import("@/views/app/sessions/SignInFour")
        },
        {
          path: "sign-in-five",
          component: () => import("@/views/app/sessions/SignInFive")
        },
        {
          path: "login",
          component: () => import("@/views/app/sessions/SignInLogin")

        },

        {
          path: "sign-up",
          component: () => import("@/views/app/sessions/SignUp")
        },
        {
          path: "sign-up-2",
          name: "signup",
          component: () => import("@/views/app/sessions/SignUpTwo")
        },
        {
          path: "sign-up-5",
          component: () => import("@/views/app/sessions/SignUpFive")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
