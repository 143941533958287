export default (to, from, next) => {
  if (
    localStorage.getItem('token_baro') != null 
  ) {
    next()
  } else {
    localStorage.removeItem('token_baro')
    next('/app/sessions/login')
  }
}
