/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

// import VueApexCharts from 'vue-apexcharts';
// mock
import "./fake-db/index.js";

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';


import { PivotViewPlugin } from "@syncfusion/ej2-vue-pivotview";

import Pivot from 'vue-webdatarocks';
import 'webdatarocks/webdatarocks.css';

import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment';


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps,{
  load: {
    key: 'AIzaSyBUX5sZ1aevGQGmrZ1RoRF3XaFJKCiTKvs',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

Vue.use(Pivot);

Vue.use(PivotViewPlugin);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm');
  }
});
// Vue.component('apexchart', VueApexCharts);

Vue.use(octaviaKit);
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
